import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import "./App.css";

interface Country {
  name: string;
  emoji: string;
}

interface Countries {
  countries: Country[];
}

const GET_COUNTRIES = gql`
  query GetCountries {
    countries {
      name
      emoji
    }
  }
`;

function shuffleCountries(a: Country[]): Country[] {
  // copied from https://stackfame.com/5-ways-to-shuffle-an-array-using-moder-javascript-es6
  // would've probably used _.shuffle() in a non test setting
  return [...a].sort(() => 0.5 - Math.random());
}

function App() {
  const { loading, error, data } = useQuery<Countries>(GET_COUNTRIES);

  return (
    <div className="App">
      {loading && <div>loading</div>}
      {error && <div>error</div>}
      {data && data.countries.length && <Game countries={data.countries} />}
    </div>
  );
}

const Game = ({ countries }: { countries: Country[] }) => {
  const numChoices = 3;
  const [gameDetails, setGameDetails] = useState<{
    score: number;
    totalGuesses: number;
    lastCorrect: boolean;
  }>({ score: 0, totalGuesses: 0, lastCorrect: false });
  const [displayedCountries, setDisplayedCountries] = useState<Country[]>([]);

  useEffect(() => {
    const tempDisplayedCountries = shuffleCountries(countries).slice(
      0,
      numChoices
    );
    setDisplayedCountries(tempDisplayedCountries);
  }, [countries, gameDetails.totalGuesses]);

  const correctCountry = displayedCountries[0];
  if (!correctCountry) return null;

  const onClickCountry = (country: Country) => {
    const gotCorrect = country.name === correctCountry.name;
    setGameDetails({
      score: gotCorrect ? gameDetails.score + 1 : gameDetails.score,
      totalGuesses: gameDetails.totalGuesses + 1,
      lastCorrect: gotCorrect
    });
  };

  const scoreColor =
    gameDetails.totalGuesses === 0
      ? "black"
      : gameDetails.lastCorrect
      ? "green"
      : "red";

  return (
    <div>
      <div style={{ fontSize: "150px" }}>{correctCountry.emoji}</div>
      <div>
        {shuffleCountries(displayedCountries).map((country) => (
          <div onClick={() => onClickCountry(country)}>{country.name}</div>
        ))}
      </div>
      <div style={{ color: scoreColor, marginTop: "10px" }}>
        score: {gameDetails.score}
      </div>
      <div>attempts: {gameDetails.totalGuesses}</div>
    </div>
  );
};

export default App;
